import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import cx from 'classnames';

import base from '../../styles/base.module.css';
import main from '../../styles/main.module.css';
import theme from '../../styles/theme.module.css';

import CleanHTML from '../cleanHTML';

/**
 * Landing page Hero component.
 * @component
 *
 * @returns {component} - <Hero />
 */

const Hero = () => {
  const {
    contentfulLanding: {
      copy: {
        childMarkdownRemark: { html },
      },
      title,
    },
  } = useStaticQuery(graphql`
    query {
      contentfulLanding {
        title
        copy {
          childMarkdownRemark {
            html
          }
        }
      }
    }
  `);

  return (
    <div
      className={cx(
        base.flex,
        base.flexColumn,
        base.itemsCenter,
        base.justifyBetween,
        base.w100,
        main.memberWrap
      )}
    >
      <h2
        className={cx(
          base.mb3,
          main.memberTitle,
          main.heading,
          theme.colorDark
        )}
      >
        {title}
      </h2>
      <CleanHTML html={html} />
    </div>
  );
};

export default Hero;
